
/**
 * Bootstrap
 */
@import "../../../node_modules/bootstrap/scss/functions";
//@import "../../../node_modules/bootstrap/scss/variables";
@import "variables";
//@import "../../../node_modules/bootstrap/scss/mixins";
//@import "../../../node_modules/bootstrap/scss/root";
//@import "../../../node_modules/bootstrap/scss/reboot";
//@import "../../../node_modules/bootstrap/scss/type";
//@import "../../../node_modules/bootstrap/scss/images";
//@import "../../../node_modules/bootstrap/scss/code";
//@import "../../../node_modules/bootstrap/scss/grid";
//@import "../../../node_modules/bootstrap/scss/tables";
//@import "../../../node_modules/bootstrap/scss/forms";
//@import "../../../node_modules/bootstrap/scss/buttons";
//@import "../../../node_modules/bootstrap/scss/transitions";
//@import "../../../node_modules/bootstrap/scss/dropdown";
//@import "../../../node_modules/bootstrap/scss/button-group";
//@import "../../../node_modules/bootstrap/scss/input-group";
//@import "../../../node_modules/bootstrap/scss/custom-forms";
//@import "../../../node_modules/bootstrap/scss/nav";
//@import "../../../node_modules/bootstrap/scss/navbar";
//@import "../../../node_modules/bootstrap/scss/card";
//@import "../../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../../node_modules/bootstrap/scss/pagination";
//@import "../../../node_modules/bootstrap/scss/badge";
//@import "../../../node_modules/bootstrap/scss/jumbotron";
//@import "../../../node_modules/bootstrap/scss/alert";
//@import "../../../node_modules/bootstrap/scss/progress";
//@import "../../../node_modules/bootstrap/scss/media";
//@import "../../../node_modules/bootstrap/scss/list-group";
//@import "../../../node_modules/bootstrap/scss/close";
//@import "../../../node_modules/bootstrap/scss/modal";
//@import "../../../node_modules/bootstrap/scss/tooltip";
//@import "../../../node_modules/bootstrap/scss/popover";
//@import "../../../node_modules/bootstrap/scss/carousel";
//@import "../../../node_modules/bootstrap/scss/utilities";
//@import "../../../node_modules/bootstrap/scss/print";

/**
 * Font Awesome
 */
@import "../../../node_modules/font-awesome/scss/font-awesome";

/**
 * Fancybox
 */
@import "vendor/jquery.fancybox.css";

/**
 * Emovi
 */

.btn {
	white-space: normal;
}

/*
.btn-primary {
	color: $gray-100;

	&:hover {
		color: $gray-800;
	}
}
*/

.hero-video {
	height: 100vh;
}

.home-logo {
	max-height: $display2-size;
	width: auto;
}

.video-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;

	video, img {
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
	}
}

/*
@media screen and (min-width: 768px) {
	.fancybox-slide--iframe .fancybox-content {
		max-width: map_get($container-max-widths, 'md')
	}
}
*/

.login-buttons {
	z-index: 2;
}

.navbar-brand img {
	max-height: $nav-link-height;
}
